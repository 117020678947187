<template>
  <div class="card">
    <div class="bg-blue-light px-1 rounded pb-2">
      <TitleButton
          class="mt-1"
          btnTitle="List"
          :showBtn="false"
          :showAddNew="true"
          title="PR Allocation"
          @onClickAddNewButton="navigateToListPage"
      />

      <div class="row mt-2 gy-1">
        <div class="row mt-1">
          <div class="col-sm-4">
            <label class="align-middle">Requester</label>
            <input type="text" class="form-control" v-model="formData.requester">
          </div>
          <div class="col-sm-4"></div>
          <div class="col-sm-4">
            <label class="align-middle">Requisition No</label>
            <input type="text" class="form-control" v-model="formData.requisition_no">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-sm-4">
            <label class="align-middle">Department</label>
            <input type="text" class="form-control" v-model="formData.department">
          </div>
          <div class="col-sm-4"></div>
          <div class="col-sm-4">
            <label class="align-middle">Requisition Date</label>
            <input type="date" class="form-control" v-model="formData.requisition_date">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-sm-4">
            <label class="align-middle">Project</label>
            <v-select
                placeholder="Select Project"
                v-model="formData.project_id"
                :options="projects"
                label="project"
                :reducer="project=> project.id"
            />
          </div>
          <div class="col-sm-4"></div>
          <div class="col-sm-4">
            <label class="align-middle">Project Budget Ref</label>
            <input type="text" class="form-control" v-model="formData.project_budget_ref">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-sm-4">
            <label class="align-middle">Business</label>
            <input type="text" class="form-control" v-model="formData.business">
          </div>
          <div class="col-sm-4"></div>
          <div class="col-sm-4">
            <label class="align-middle">Status</label>
            <v-select
                placeholder="PR Allocation Status"
                v-model="formData.status"
                :options="statuses"
                label="status"
                :reducer="status => status.id"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="px-2 mt-2">
      <AddItemDetails
          class="mb-2"
          :products="products"
          v-for="(item, index) in formData.item_details"
          :key="index"
          :index="index"
          :item="item"
          @onClose="onClose"
      />
    </div>
    <div class="row me-1 mt-3 px-2">
      <div class="col-12 col-sm-4 col-lg-8 mb-1 mb-sm-0">
        <AddButton title="Add line" @onClickAdd="onClickAdd"/>
      </div>
    </div>

    <div class="p-1">
      <div class="row mt-3 px-2">

        <div class="col-md-7">
          <div class="row">
            <div class="col-12">
              <p class="h2 m-0">Attachment</p>
            </div>
          </div>
          <hr/>
          <div class="row d-flex flex-wrap justify-content-between align-items-center">
            <div class="col-3">
              <div class="card border-1">
                <div class="card-body">
                  <img src="http://dev.miaccounts.net/images/avatar.jpg" class="card-img-top" alt="img">
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="card border-1">
                <div class="card-body">
                  <img src="http://dev.miaccounts.net/images/avatar.jpg" class="card-img-top" alt="img">
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="card border-1">
                <div class="card-body">
                  <img src="http://dev.miaccounts.net/images/avatar.jpg" class="card-img-top" alt="img">
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="card border-1">
                <div class="card-body">
                  <img src="http://dev.miaccounts.net/images/avatar.jpg" class="card-img-top" alt="img">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-5">
          <div class="row mt-1">
            <div class="col-md-4 align-middle">SubTotal :</div>
            <div class="col-md-8">
              <input class="form-control text-end" :value="formData.sub_total" readonly>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-md-4 align-middle">VAT :</div>
            <div class="col-md-8">
              <input class="form-control text-end" :value="formData.vat" readonly>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-md-4 align-middle">Total :</div>
            <div class="col-md-8">
              <input class="form-control text-end" :value="formData.total" readonly>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-9">
              <p class="h2 m-0">Memo</p>
            </div>
          </div>
          <hr/>
          <div class="mb-2">
            <textarea v-model="formData.memo" placeholder="Memo" class="form-control" rows="2" id="memo"></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="p-2">
      <div class="row">
        <div class="col-9">
          <p class="h2 m-0">Approval Matrix</p>
        </div>
      </div>
      <hr/>
      <div class="row d-flex flex-wrap justify-content-between align-items-center">
        <div class="col-3">
          <div class="card border-1">
            <div class="card-body">
              <h5 class="card-title text-center">1</h5>
              <img src="http://dev.miaccounts.net/images/avatar.jpg" class="card-img-top" alt="img">
              <p class="card-text mt-2">
                User Name <br>
                Designation
              </p>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="card border-1">
            <div class="card-body">
              <h5 class="card-title text-center">2</h5>
              <img src="http://dev.miaccounts.net/images/avatar.jpg" class="card-img-top" alt="img">
              <p class="card-text mt-2">
                User Name <br>
                Designation
              </p>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="card border-1">
            <div class="card-body">
              <h5 class="card-title text-center">3</h5>
              <img src="http://dev.miaccounts.net/images/avatar.jpg" class="card-img-top" alt="img">
              <p class="card-text mt-2">
                User Name <br>
                Designation
              </p>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="card border-1">
            <div class="card-body">
              <h5 class="card-title text-center">Final Approval</h5>
              <img src="http://dev.miaccounts.net/images/avatar.jpg" class="card-img-top" alt="img">
              <p class="card-text mt-2">
                User Name <br>
                Designation
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-2 d-flex justify-content-start justify-content-sm-center px-2 gap-1 flex-wrap pb-2">
      <hr class="hr-full-width mb-1">
      <button @click="handleReject()"
              class="btn btn-danger">
        Reject
      </button>
      <button @click="handleReturn()"
              class="btn btn-outline-secondary">
        Return
      </button>
      <button @click="handleHold()"
              class="btn btn-outline-secondary">
        Hold
      </button>
      <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(true)"
              class="btn btn-primary">
        Proceed
      </button>
    </div>

    <GlobalLoader/>

  </div>
</template>
<script setup>
import {inject, ref} from 'vue'
import {useRoute, useRouter} from "vue-router"
import TitleButton from '@/components/atom/TitleButton'
import AddItemDetails from "@/components/molecule/procurement/requisition/pr-allocation/AddItemDetails"
import AddButton from "@/components/atom/AddButton"

const router = useRouter()
const route = useRoute()
const showError = inject('showError')
const showSuccess = inject('showSuccess')

let saveButtonLoader = ref(false)
let saveNewButtonLoader = ref(false)
let commentModalTitle = ref('');
let projects = ref([])
let products = ref([])
let formData = ref({
  requester: null,
  requisition_no: null,
  department: null,
  requisition_date: null,
  project_id: null,
  project_budget_ref: null,
  business: null,
  status: null,
  sub_total: 0.00,
  vat: 0.00,
  total: 0.00,
  memo: null,
  item_details: [
    {
      product_id: null,
      total_amount: null,
      procurement_type: null,
      note: null,
    }
  ],
})

function navigateToListPage() {
  router.push({name: `pr-allocation-list`, params: route.params, query: route.query});
}

function handleSubmit() {
  // TODO
}

function handleReject() {
  // TODO
}

function handleReturn() {
  // TODO
}

function handleHold() {
  // TODO
}

function onClose(index) {
  formData.value.item_details.splice(index, 1)
}

function onClickAdd() {
  formData.value.item_details.push({
    product_id: null,
    total_amount: null,
    procurement_type: null,
    note: null,
  });
}
</script>